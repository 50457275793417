<app-navbar [nameSection]="name"></app-navbar>

<!-- LOADER -->
<div *ngIf="showLoader" class="loader-container">
    <div class="overlayLoader">
        <div class="loader"></div>
    </div>
</div>

<!-- CHECKLIST, BOTONES IMAGENES Y INCIDENCIAS -->
<div *ngFor="let check of checkLists" class="container-fluid">
    <div class="row mt-2">
        <div class="col-12">
            <input (click)="clickCheck($event, check.nom)" *ngIf="check.checked === 0 || check.codi; else checked"
                   class="form-check-input col-2" id="{{check.idCheck}}"
                   name="{{check.idLloc}}" type="checkbox">
            <label *ngIf="check.checked === 0 || check.codi" class="form-check-label col-10"
                   for="{{check.id}}" style="margin-top: -5px; margin-left: 5px;">
                {{ check.nom }} - {{ check.nomDesc }}
            </label>
        </div>

        <ng-container *ngIf="modeTransport === 'AERI' && tipusTransport === 'Exportació' && check.nom === 'Llegada al punto de descarga.'">
            <div class="col-12 mt-3">
                <button class="form-control" id="buttonCheckBultosDestinyAWB" style="height: 43px"
                        (click)="openModalCheckBultosDestinyAWB(check.idCheck)"
                        [ngClass]="{'clase-css-true': check.isCheckedBultosDestinyAWB === 1, 'clase-css-false': check.isCheckedBultosDestinyAWB === 0}">
                    Comprobación bultos y destino AWB
                </button>
            </div>
            <div class="col-12 mt-2">
                <button class="form-control" id="buttonBarcode" style="height: 43px" (click)="openModalCheckAWB()"
                        [ngClass]="{'clase-css-true': check.isCheckedAWB === 1, 'clase-css-false': check.isCheckedAWB === 0}">
                    Comprobación etiqueta AWB
                </button>
            </div>
        </ng-container>

        <ng-template #checked>
            <div class="col-12">
                <input (click)="clickCheck($event, check.nom)" checked class="form-check-input col-2" id="{{check.idCheck}}" type="checkbox" disabled>
                <label class="form-check-label col-10" for="{{check.id}}" style="margin-top: -5px; margin-left: 5px;">
                    {{ check.nom }} - {{ check.nomDesc }}
                </label>
            </div>

            <div class="col-12 mt-3" style="display: grid; justify-items: center;">
                <input (change)="previewUploadFotoLlegadasSalidas($event, check.reference, check.idCheck, false, '')"
                       id="albaranPhotoCheckInput" name="file" type="file">
                <label #clickIncidencia class="form-control btn w-100" for="albaranPhotoCheckInput"
                       id="albaranPhotoCheckLabel">
                    <span>Foto conforme llegada o salida.</span>
                </label>

                <div *ngIf="check.hasImage === 1" class="card mb-3 mt-2 w-100" id="cardPhotoDone">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <span><b>Foto hecha</b></span>
                            </div>
                            <div class="col-6">
                                <button [routerLink]="['/show-images', check.reference, idTransport]"
                                        class="form-control float-end"
                                        id="buttonMinimizarIncidencia" style="background-color: #3c2170; padding: 0;">
                                    Ver fotografías
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="row mt-2">
        <div class="col-2">
            <input (click)="clickIncidecia(check.id)"
                   *ngIf="!check.incidenciaMarcada || check.codi; else checkedIncidencia" class="form-check-input mt-2"
                   id="inputCheckboxIncidence" name="{{check.idCheck}}"
                   type="checkbox">
        </div>

        <ng-template #checkedIncidencia>
            <div *ngIf="!check.incidenceDisabled" class="col-2">
                <input (click)="clickIncidecia(check.id)" checked
                       class="form-check-input mt-2 mb-2" id="inputCheckboxIncidence2" name="{{check.idCheck}}"
                       type="checkbox">
            </div>

            <div *ngIf="check.incidenceDisabled" class="col-2">
                <input (click)="clickIncidecia(check.id)" checked
                       class="form-check-input mt-2 mb-2" disabled id="inputCheckboxIncidence3"
                       name="{{check.idCheck}}" type="checkbox">
            </div>
        </ng-template>

        <label class="form-check-label col-10" for="{{check.id}}"
               id="labelCheckboxIncidencias" style="margin-top: 10px;">Incidencia</label>

        <div class="col-12 mt-3" style="display: grid; justify-items: center;">
            <button (click)="generateNewIncidencia(check.id)" *ngIf="check.incidencia.length > 0"
                    class="form-control" style="height: 42px;">
                Añadir nueva incidencia
            </button>
        </div>
    </div>

    <ng-container *ngFor="let incidencia of check.incidencia">
        <app-albaran-progress-incidencia-list [incidencia]="incidencia"></app-albaran-progress-incidencia-list>
    </ng-container>

    <ng-container *ngIf="check.newIncidence">
        <app-albaran-progress-new-incidencia [idReference]="reference" [idTransport]="idTransport"
                                             [newIncidencia]="check"></app-albaran-progress-new-incidencia>
    </ng-container>
    <hr>
</div>

<!--<div [hidden]="!uploadPhotoAlbaran" class="container-fluid mb-3">
    <div class="" style="display: grid; justify-items: center;">
        <input (change)="filePhotoAlbaran($event)" id="albaranPhotoInput" name="file" type="file">
        <label #clickUploadPOD class="form-control btn w-100" for="albaranPhotoInput" id="albaranPhotoLabel">
            <span><b>FOTO ALBARÁN (POD)</b></span>
        </label>
    </div>
</div>-->

<!-- MODALS CONFIRMACIÓN FOTOGRAFÍA -->

<!-- MODAL CROPPER -->
<div class="container-fluid">
    <button #buttonModal [attr.data-bs-target]="'#cesta'" [attr.data-bs-toggle]="'modal'" [hidden]="buttonHidden"
            class="btn btn-dark"></button>

    <div [attr.aria-hidden]="'true'" [attr.aria-labelledby]="'staticBackdropLabel'" [attr.data-bs-backdrop]="'static'"
         [attr.data-bs-keyboard]="'false'"
         class="modal fade" id="cesta" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="display: flex; justify-content: center; align-items: center;">
                    <h5 class="modal-title" id="staticBackdropLabel">Confirmación imagen</h5>
                </div>
                <div class="modal-body">
                    <image-cropper
                        [hidden]="isCroppedImage || boolUploadLlegadaSalida"
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="false"
                        format="jpeg"
                        (imageCropped)="imageCropped($event)"
                    ></image-cropper>

                    <div class="container-fluid" style="display: flex; justify-content: center; align-items: center;">
                        <img [hidden]="!isCroppedImage" [src]="croppedImage" width="255" height="350" alt="">
                        <img *ngIf="boolUploadLlegadaSalida" alt="" src="data:image/png;base64,{{base64image}}"
                             width="255" height="350">
                    </div>
                </div>
                <div class="modal-footer" style="display: flex; justify-content: center; align-items: center;">
                    <button (click)="cropImage()" [hidden]="isCroppedImage || boolUploadLlegadaSalida"
                            class="form-control mb-1" type="button">
                        Recortar imagen y CONFIRMAR
                    </button>

                    <button (click)="uploadFotoAlbaran()" [hidden]="!isCroppedImage"
                            class="form-control mb-1" data-bs-dismiss="modal" type="button">
                        Guardar
                    </button>
                    <button (click)="uploadFotoLlegadasSalidas(true)" [hidden]="!boolUploadLlegadaSalida"
                            class="form-control mb-1" data-bs-dismiss="modal" type="button">
                        Guardar
                    </button>

                    <button (click)="closeModal()" class="form-control" data-bs-dismiss="modal" type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL FOTO LLEGADAS O SALIDAS -->
<div class="container-fluid">
    <button #individualPhotoLlegadasSalidasModal [attr.data-bs-target]="'#individualPhotoLlegadasSalidas'"
            [attr.data-bs-toggle]="'modal'" [hidden]="buttonHidden"
            class="btn btn-dark"></button>

    <div [attr.aria-hidden]="'true'" [attr.aria-labelledby]="'individualPhotoLlegadasSalidasLabel'"
         [attr.data-bs-backdrop]="'static'"
         [attr.data-bs-keyboard]="'false'"
         class="modal fade" id="individualPhotoLlegadasSalidas" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="display: flex; justify-content: center; align-items: center;">
                    <h5 class="modal-title" id="individualPhotoLlegadasSalidasLabel">Confirmación imagen</h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" style="display: flex; justify-content: center; align-items: center;">
                        <img [hidden]="!isCroppedImage" [src]="croppedImage" width="255" height="350" alt="">
                        <img *ngIf="boolUploadLlegadaSalida" alt="" src="data:image/png;base64,{{base64image}}"
                             width="255" height="350">
                    </div>
                </div>
                <div class="modal-footer" style="display: flex; justify-content: center; align-items: center;">
                    <button (click)="uploadFotoLlegadasSalidas(false)"
                            class="form-control mb-1" data-bs-dismiss="modal" type="button">
                        Guardar
                    </button>
                    <button (click)="closeModal()" class="form-control" data-bs-dismiss="modal" type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL FOTO SRCB -->
<div class="container-fluid">
    <button #modalMandatoryPhotoSRCB [attr.data-bs-target]="'#mandatoryPhotoSRCB'"
            [attr.data-bs-toggle]="'modal'" [hidden]="buttonHidden"
            class="btn btn-dark"></button>

    <div [attr.aria-hidden]="'true'" [attr.aria-labelledby]="'mandatoryPhotoSRCBLabel'" [attr.data-bs-backdrop]="'static'" [attr.data-bs-keyboard]="'false'"
         class="modal fade" id="mandatoryPhotoSRCB" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="display: flex; justify-content: center; align-items: center;">
                    <h5 class="modal-title" id="mandatoryPhotoSRCBLabel">Confirmación imagen</h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" style="display: flex; justify-content: center; align-items: center;">
                        <img [hidden]="!isCroppedImage" [src]="croppedImage" width="255" height="350" alt="">
                        <img *ngIf="boolUploadLlegadaSalida" alt="" src="data:image/png;base64,{{base64image}}"
                             width="255" height="350">
                    </div>
                </div>
                <div class="modal-footer" style="display: flex; justify-content: center; align-items: center;">
                    <button (click)="uploadPhotoSRCB()" class="form-control mb-1" data-bs-dismiss="modal" type="button">
                        Guardar
                    </button>
                    <button (click)="closeModalSRCB()" class="form-control" data-bs-dismiss="modal" type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODALS PREGUNTAS FLUJO CHECKLIST -->

<!-- MODALS LLPC -->
<div *ngIf="showListLlegadasLLPC" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca los albaranes extra que vas a hacer en este mismo sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesLlegadas">
                <div class="content col-12">
                    <input class="form-check-input col-2" type="checkbox" id="{{albaran.id}}"
                           (click)="saveDeliveryNoteWaypoints(albaran, $event)">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>
        <hr>
        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control mb-2" (click)="marcarMasAlbaranesLlegada()">
                Marcar los siguientes albaranes
            </button>
            <button class="form-control" (click)="closeMarcarAlbaranesLlegadas()">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>

<!-- MODALS SPC -->
<div *ngIf="showListMoreAlbaransSPC" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca todos los albaranes para salir de este sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesSPC">
                <div class="content col-12">
                    <input class="form-check-input col-2" type="checkbox" id="{{albaran.id}}"
                           (click)="saveDeliveryNoteWaypoints(albaran, $event)">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control mb-2" (click)="marcarMasAlbaranesSPC()">
                Marcar los siguientes albaranes
            </button>
            <button class="form-control mb-2" (click)="closeMarcarAlbaranesSPC()">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>
<div *ngIf="showListExtraPhotosSPC" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Sube las fotografías de salida obligatorias de cada albarán</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2" id="photoBillingExtra">
            <ng-container *ngFor="let albaran of albaranesMarcadosLlegadas">
                <div class="content col-12" [id]="getId(albaran.reference)">
                    <input
                        (change)="previewUploadFotoLlegadasSalidas($event, albaran.reference, albaran.idCheck, true, 'SPC')"
                        id="{{albaran.reference}}" name="file" type="file">
                    <label class="form-control btn w-100" for="{{albaran.reference}}"
                           id="albaranPhotoSalidasExtraLabel-{{albaran.reference}}">
                        <span>{{albaran.reference}}</span>
                        <br>
                        <span>{{albaran.bultos}}b|{{albaran.kg}}kg|{{albaran.volumen | number:'1.2-2'}}m³</span>
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="closeModalPhotosBillingsExtra()" [disabled]="!billingsPhotosDone">
                {{ isMultitasking ? "Siguiente" : "Terminar" }}
            </button>
        </div>
    </div>
</div>
<div *ngIf="showListNextPointsSPC" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Hacia que sitio te dirijes ahora?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let llocs of llocsAlbaranSPC">
                <div class="content col-12">
                    <input class="form-check-input col-2" type="checkbox" id="{{llocs.idLloc}}"
                           [checked]="selectedCheckboxId === llocs.idLloc.toString()"
                           (click)="saveDestinationPlaceId(llocs.idLloc)">
                    <label class="form-check-label col-8" for="{{llocs.idLloc}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ llocs.nom }} - {{ llocs.cliente }} - {{ llocs.bultos }} - {{ llocs.kg }}
                        - {{ llocs.numAlbaran }}
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="sendMoreAlbaransSPC()">
                Enviar
            </button>
        </div>
    </div>
</div>

<!-- MODALS LLPD -->
<div *ngIf="showListLLPD" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca los albaranes extra que vas a hacer en este mismo sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesLLPD">
                <div class="content col-12">
                    <input class="form-check-input col-2" type="checkbox" id="{{albaran.id}}"
                           (click)="saveDeliveryNoteWaypoints(albaran, $event)">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>
        <hr>
        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control mb-2" (click)="marcarMasAlbaranesLLPD()">
                Marcar los siguientes albaranes
            </button>
            <button class="form-control" (click)="closeMarcarAlbaranesLLPD()">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>

<!-- MODALS SPD -->
<div *ngIf="showListMoreAlbaransSPD" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca todos los albaranes para salir de este sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesSPD">
                <div class="content col-12">
                    <input class="form-check-input col-2" type="checkbox" id="{{albaran.id}}"
                           (click)="saveDeliveryNoteWaypoints(albaran, $event)">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control mb-2" (click)="sendMoreAlbaransSPD()">
                Marcar los siguientes albaranes
            </button>
            <button class="form-control mb-2" (click)="closeMarcarAlbaranesSPD()">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>
<div *ngIf="showListPhotosPodSPD" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Sube las fotografías de salida obligatorias de cada albarán (POD)</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2" id="photoBillingExtraSPD">
            <ng-container *ngFor="let albaran of albaranesMarcadosLlegadas">
                <div class="content col-12" [id]="getIdPOD(albaran.reference)">
                    <input (change)="filePhotoBillingsExtra($event, albaran.reference)" id="{{albaran.reference}}"
                           name="file"
                           type="file">
                    <label class="form-control btn w-100" for="{{albaran.reference}}"
                           id="albaranPhotoSalidasExtraLabel-{{albaran.reference}}">
                        <span>{{ albaran.reference }}</span>
                        <br>
                        <span>{{albaran.bultos}}b|{{albaran.kg}}kg|{{albaran.volumen | number:'1.2-2'}}m³</span>
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="closeModalPhotosBillingsExtraSPD()"
                    [disabled]="!billingsPhotosPODDone">
                Terminar
            </button>
        </div>
    </div>
</div>

<!-- MODALS LLPP -->
<div *ngIf="showListLLPP" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca los albaranes extra que vas a hacer en este mismo sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesLLPP">
                <div class="content col-12">
                    <input class="form-check-input col-2" type="checkbox" id="{{albaran.id}}"
                           (click)="saveDeliveryNoteWaypoints(albaran, $event)">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control mb-2" (click)="marcarMasAlbaranesLLPP()">
                Marcar los siguientes albaranes
            </button>
            <button class="form-control" (click)="closeMarcarAlbaranesLLPP()">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>

<!-- MODALS SPP -->
<div *ngIf="showListSPP" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3 *ngIf="showListMoreAlbarans">Marca todos los albaranes para salir de este sitio.</h3>
            <h3 *ngIf="showListNextPointsSPP && !showListMoreAlbarans">Hacia que sitio te dirijes ahora?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2" *ngIf="showListMoreAlbarans">
            <ng-container *ngFor="let albaran of albaranesSPP">
                <div class="content col-12">
                    <input class="form-check-input col-2" type="checkbox" id="{{albaran.id}}"
                           (click)="saveDeliveryNoteWaypoints(albaran, $event)">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>
        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2"
             *ngIf="showListNextPointsSPP && !showListMoreAlbarans">
            <ng-container *ngFor="let llocs of llocsAlbaranSPP">
                <div class="content col-12">
                    <input class="form-check-input col-2" type="checkbox" id="{{llocs.idLloc}}"
                           [checked]="selectedCheckboxId === llocs.idLloc.toString()"
                           (click)="saveDestinationPlaceId(llocs.idLloc)">
                    <label class="form-check-label col-8" for="{{llocs.idLloc}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ llocs.nom }} - {{ llocs.cliente }} - {{ llocs.bultos }} - {{ llocs.kg }}
                        - {{ llocs.numAlbaran }}
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button *ngIf="showListMoreAlbarans" class="form-control mb-2" (click)="marcarMasAlbaranesSPP()">
                Marcar los siguientes albaranes
            </button>
            <button *ngIf="showListMoreAlbarans" class="form-control mb-2" (click)="closeMarcarAlbaranesSPP()">
                No marcar ninguno más
            </button>
            <button *ngIf="showListNextPointsSPP && !showListMoreAlbarans" class="form-control"
                    (click)="sendMoreAlbaransSPP()">
                Enviar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showListNextPointsSPP && !showListSPP" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Hacia que sitio te dirijes ahora?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let llocs of llocsAlbaranSPP">
                <div class="content col-12" style="max-height: 200px; overflow-y: auto;">
                    <input class="form-check-input col-2" type="checkbox" id="{{llocs.idLloc}}"
                           [checked]="selectedCheckboxId === llocs.idLloc.toString()"
                           (click)="saveDestinationPlaceId(llocs.idLloc)">
                    <label class="form-check-label col-8" for="{{llocs.idLloc}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ llocs.nom }} - {{ llocs.cliente }} - {{ llocs.bultos }} - {{ llocs.kg }}
                        - {{ llocs.numAlbaran }}
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="sendMoreAlbaransSPP()">
                Enviar
            </button>
        </div>
    </div>
</div>

<!-- MODALS SRCB -->
<div *ngIf="showMandatoryPhoto" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Sube la fotografía obligatoria de la salida</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2" id="mandatoryPhoto">
            <div class="content col-12" [hidden]="mandatoryPhotoDone">
                <input (change)="previewUploadMandatoryPhotoSRCB($event)"
                    id="mandatoryPhotoInput" name="file" type="file">
                <label class="form-control btn w-100" for="mandatoryPhotoInput" id="mandatoryPhotoLabel">
                    <span>Fotografía obligatoria</span>
                </label>
            </div>
            <div class="content col-12" [hidden]="!mandatoryPhotoDone">
                <span>Fotografía hecha correctamente.</span>
            </div>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="closeModalMandatoryPhotoSRCB()" [disabled]="!mandatoryPhotoDone">
                Terminar
            </button>
        </div>
    </div>
</div>

<!-- MODALS COMPROBACIÓN BULTOS Y DESTINO AWB -->
<div *ngIf="showCheckBultosAWB" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>¿Las marcas del bulto corresponden con {{originNamesLinkedAlbarans}}?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkBultosAWB">
            <div class="content col-12" [hidden]="checkedAppropiate">
                <button class="form-control" (click)="isAppropriate()">Si</button>
                <button class="form-control mt-2" (click)="notIsAppropriate()">No</button>
            </div>

            <div class="content col-12" [hidden]="!isNotAppropiate">
                <button (click)="goBackBultos()" class="form-control mb-4" style="width: 40px !important;">
                    <i class="fa fa-arrow-left"></i>
                </button>
                <p>
                    Por favor llama a la oficina del aeropuerto para comprobar la mercancía.
                </p>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="closeModalCheckBultosAWB()">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckDestinyAWB" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>¿Coincide el destino de la etiqueta del AWB con el destino indicado en los bultos?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkDestinyAWB">
            <div class="content col-12" [hidden]="checkedAppropiateDestiny">
                <button (click)="goBackToBultos()" class="form-control mb-4" style="width: 40px !important;">
                    <i class="fa fa-arrow-left"></i>
                </button>

                <button class="form-control" (click)="isAppropriateDestiny()">Si</button>
                <button class="form-control mt-2" (click)="notIsAppropriateDestiny()">No</button>
                <button class="form-control mt-2" (click)="checkDestinyIATA()">Consultar siglas destino, código IATA</button>
            </div>

            <div class="content col-12" [hidden]="!isNotAppropiateDestiny">
                <button (click)="goBackDestiny()" class="form-control mb-4" style="width: 40px !important;">
                    <i class="fa fa-arrow-left"></i>
                </button>
                <p>
                    Por favor si el destino de la etiqueta no coincide pongase en contacto con el personal de la oficina.
                </p>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="closeModalCheckDestinyAWB()">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckDestinyIATA" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3 [hidden]="checkedAppropiateDestinyIATA">¿Coincide el destino de la etiqueta del AWB con el destino indicado en los bultos?</h3>
            <h3 [hidden]="!checkedAppropiateDestinyIATA"><b>ATENCIÓN</b></h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkDestinyIATA">
            <div class="content col-12" [hidden]="checkedAppropiateDestinyIATA">
                <button (click)="goBackCheckIATA()" class="form-control mb-4" style="width: 40px !important;">
                    <i class="fa fa-arrow-left"></i>
                </button>

                <label for="inputCheckDestinyIATA">Consultar siglas destino, código IATA</label>
                <input type="text" class="form-control" id="inputCheckDestinyIATA" [(ngModel)]="codeIATAtoCheck">
            </div>

            <div class="content col-12" [hidden]="!checkedAppropiateDestinyIATA">
                <span>
                    Este es el el aeropuerto/ciudad: {{nomAeroport}}/{{municipiAeroport}} y el país: {{paisAeroport}}.
                    <b>¿Coincide el destino de la etiqueta del AWB con el destino indicado en los bultos?</b>
                </span>
                <button class="form-control mt-2" (click)="isAppropriateDestiny()">Si</button>
                <button class="form-control mt-2" (click)="notIsAppropriateDestinyIATA()">No</button>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control mb-2" (click)="funcCheckDestinyIATA()" [hidden]="checkedAppropiateDestinyIATA">
                Comprobar IATA
            </button>
            <button class="form-control" (click)="closeModalCheckDestinyIATA()">
                Cerrar
            </button>
        </div>
    </div>
</div>

<!-- MODALS COMPROBACIÓN AWB -->
<div *ngIf="showCheckAWB" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Asegúrate que la etiqueta está pegada en el bulto. ¿Qué comprobación vas a seguir?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkAWB">
            <div class="content col-12">
                <button class="form-control" (click)="partialCheck()">Comprobación simple</button>
                <button class="form-control mt-2" (click)="totalCheck()">Comprobación total</button>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="closeModalCheckAWB()">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckAWBScanner" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Escanea los códigos de barras de la etiqueta para verificar el bulto.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkAWBscanner">
            <div class="content col-12">
                <button class="form-control mb-4" (click)="scannBarcodeManually()" style="background-color: #f2e938; color: black; border-color: #f2e938">
                    <b>Verificación manual número AWB</b>
                </button>

                <div class="qr-scanner">
                    <div class="box">
                        <div class="line"></div>
                        <div class="angle"></div>
                    </div>
                </div>

                <div>
                    <zxing-scanner #scanner (camerasFound)="onCamerasFound($event)"
                                   [formats]="formatsEnabled"
                                   (camerasNotFound)="browserError('No existen camaras disponibles')"
                                   (deviceChange)="onDeviceChange($event)"
                                   (permissionResponse)="onHasPermission($event)"
                                   (scanSuccess)="checkBrowser($event)"
                                   (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
                </div>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="closeModalCheckAWBScanner()">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckAWBManually" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Introduce manualmente el número de AWB para su verificación.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkAWBManually">
            <div class="content col-12">
                <label for="inputCheckBarcodeManually">Introduce el número AWB</label>
                <input type="text" class="form-control" id="inputCheckBarcodeManually" #inputCheckBarcodeManually>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="sendBarcodeManually()">
                Enviar
            </button>
            <button class="form-control mt-2" (click)="closeModalCheckAWBManually()">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckAWBDone" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Escanea los codigos de barras del AWB para verificar el bulto.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkAWBDone">
            <div class="content col-12">
                <span id="{{idMessageCheckAWBDone}}">
                    {{messageCheckAWBDone}}
                </span>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control" (click)="scannNextBarcode()" [hidden]="isPartialSuccess || isTotalSuccess">
                Escanear siguiente codigo de barras
            </button>
            <button class="form-control mt-2" (click)="closeModalCheckAWBDone()" *ngIf="isPartialSuccess || isTotalSuccess">
                Terminar proceso
            </button>
        </div>
    </div>
</div>

<div *ngIf="showAlertSuccessScannBarcode" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageSuccessScannBarcode }}</p>
            <button (click)="closeAlertSuccessScannBarcode()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTAS -->

<!-- ALERTAS DE OK Y KO EN LA SUBIDA DE LA IMAGEN DEL ALBARÁN -->
<div *ngIf="showAlertSuccessUploadPhotoAlbaran" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageSuccessAlbaran }}</p>
            <button (click)="closeAlertSuccessAlbaran()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>
<div *ngIf="showAlertErrorUploadPhotoAlbaran" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageErrorAlbaran }}</p>
            <button (click)="closeAlertErrorAlbaran()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA DE INCIDENCIA POR MARCAR CHECK TARDÍO -->
<div *ngIf="showAlertCheckTardio" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageCheckTardio }}</p>
            <button (click)="closeAlertCheckTardio()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA DE KO O OK FOTOGRAFIA LLEGADA O SALIDA -->
<div *ngIf="showAlertUploadFotoLLegaSalida" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertUploadFotoLLegaSalida }}</p>
            <button (click)="closeAlertUploadFotoLLegaSalida()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA DE KO O OK FOTOGRAFIA LLEGADA O SALIDA SI NO MARCA SITIO AL QUE SE DIRIGE -->
<div *ngIf="showAlertPlaceToGo" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertPlaceToGo }}</p>
            <button (click)="closeAlertPlaceToGo()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA FALLO AL CREAR LOS CHECKS -->
<div *ngIf="showAlertCreateChecks" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertCreateChecks }}</p>
            <button (click)="closeAlertCreateChecks()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA FALLO AL CREAR LOS CHECKS -->
<div *ngIf="showAlertAlbaranCompletedSECP" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertAlbaranCompletedSECP }}</p>
            <button (click)="closeAlertAlbaranCompletedSECP()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>
